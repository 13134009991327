import { ref, reactive ,computed} from "vue";
import useTableHeight from "@/hooks/useTableHeight";
import { avaliablePrograms ,getUser,exportAvaliablePrograms ,getStatus,getOfferType,getDealerMessageApi  } from "@/API/claim/delaerClaim/delaer";
import moment from 'moment'
import downloadFile from "@/utils/claim/downloadFile";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
// const value3 = ref<Moment[]>([]);
const store = useStore();
// console.log((store.state.user as any).organization.entityCode)
const dealerCode = ref('193120340');
const dealerNameEN = ref('');
const dealerNameCN = ref('');
// const dealerCode =  (store.state.user as any).organization.entityCode;
// const dealerNameEN = (store.state.user as any).organization.entityNameEn;
// const dealerNameCN = (store.state.user as any).organization.entityName;


// console.log('aaa')

// const dealerCode = (store.state.user as any).organization.entityCode;
// const dealerNameEN = (store.state.user as any).organization.entityNameEn;
// const dealerNameCN = (store.state.user as any).organization.entityName;
// debugger

const queryStatusArray = ref<any[]>([]);
const buList = ref<string[]>([]);
const useDealerList = () => {
    //----------------------筛选条件的数据及操作---------------------


    interface ScreeningConditionsType {
      incentiveCategory: string|null;
      category: string|null;
      incentiveTime: string[];
      status: string|null;
      programCode: string|null;
      programName: string|null;
      declareTime: string[];
   }
    //筛选条件，各个信息框的内容
    const screeningConditions: ScreeningConditionsType = reactive({
      incentiveCategory: null,
      category: null,
      incentiveTime: [],
      status: null,
      programCode: null,
      programName: null,
      // declareTime: [],
      declareTime: [],
    });

    //筛选条件，用于查询
    const queryScreeningConditions: ScreeningConditionsType = reactive({
      incentiveCategory: null,
      category: null,
      incentiveTime: [],
      status: null,
      programCode: null,
      programName: null,
      declareTime: [],
    });
    const disableCategory = ref(true);
    //清空筛选信息
    const clearScreeningConditions = () => {
        screeningConditions.incentiveCategory = null;
        screeningConditions.category = null;
        screeningConditions.incentiveTime = [];
        screeningConditions.status = null;
        screeningConditions.programCode = null;
        screeningConditions.programName = null;
        screeningConditions.declareTime = [];

        queryScreeningConditions.incentiveCategory = null;
        queryScreeningConditions.category = null;
        queryScreeningConditions.incentiveTime = [];
        queryScreeningConditions.status = null;
        queryScreeningConditions.programCode = null;
        queryScreeningConditions.programName = null;
        queryScreeningConditions.declareTime = [];

        disableCategory.value = true;
    };


    //----------------------表格的数据及操作---------------------
    //分页组件
    const pagination = reactive({
        pageSize:20,
        currentPage:1,
        total:0
    })
  
    //表格表头
    const columns = reactive([
        {
            title: "项目编号",
            dataIndex: "programCode",
            key: "programCode",
            width:120,
        },
        {
            title: "项目名称",
            dataIndex: "programNameCn",
            key: "programNameCn",
            width:200,
        },
        {
            title: "项目期间/申报截止期间",
            dataIndex: "programPeriod",
            key: "programPeriod",
            width:330,
            slots: { customRender: 'programPeriod' },
        },
        {
          // title: "待申报VIN",
          dataIndex: "undeclaredVin",
          key: "undeclaredVin",
          width:120,
          slots: { title: 'customTitle', customRender: 'undeclaredVin' },
        },
        {
          title: "已申报VIN",
          dataIndex: "declaredVin",
          key: "declaredVin",
          width:100,
        },
        {
          title: "项目状态",
          dataIndex: "status",
          key: "status",
          width:120,
        },
        {
          title: "项目类型",
          dataIndex: "category",
          key: "category",
          width:100,
        },
        { title: '操作',width:100,fixed: 'right', slots: { customRender: 'operation' } },
    ]);


    //表格高度

    const tableHeight = window.innerHeight - 500;
    // const { tableHeight } = useTableHeight();

    interface ProgramPeriodType {
      periodBegin: string;
      periodEnd: string;
      submissionBegin: string;
      submissionEnd: string;
    }

    interface TableType  {
      category: string;
      declaredVin: number;
      undeclaredVin: number;
      programCode: string; 
      programId: string;
      // programName: string;
      programNameCn: string;
      programNameEn: string;
      programPeriod: ProgramPeriodType[];
      status: string;
      totalVin: number;//总数
    }

    //显示的表格数据
    const dataSource = ref<TableType[]>([]);

    
    // //查询表格方法
    // const getTableData = (url: string) => {
    //   console.log(url);
    //   console.log("查询表格");

    //   const params = {dealerCode:"Dealer"}; 
    //   Object.assign(params,screeningConditions);
    //   Object.assign(params,pagination);
    //   console.log(params)
    // };

    //记录到底是怎么查询出的表格，用于分页切换
    const isOverView = ref(false);
    const overViewOption = ref<any>({});
    //导出记录
    let exportParams = {}
    //导出项目明细
    const exportTable = () =>{
      console.log(exportParams)
      const par = {
        url: `/claimapi/dealer/exportAvaliablePrograms`,
        method: "post",
        params: Object.assign(exportParams,{current:'',size:""}),
      };
      downloadFile(par, "application/vnd-excel; char-set=UTF-8").then(
        (res) => {
          console.log(res);
        }
      );
      
      // exportAvaliablePrograms(params).then((res: any): void => {
      //   console.log(res)

      // })

    }



    const isExport = ref(false);
    
    //根据筛选框信息，查询表格
    const getTableDataByScreeningConditions = () => {

      queryScreeningConditions.incentiveCategory = screeningConditions.incentiveCategory;
      queryScreeningConditions.category = screeningConditions.category;
      queryScreeningConditions.incentiveTime = screeningConditions.incentiveTime;//?
      queryScreeningConditions.status = screeningConditions.status;
      queryScreeningConditions.programCode = screeningConditions.programCode;
      queryScreeningConditions.programName = screeningConditions.programName;
      queryScreeningConditions.declareTime = screeningConditions.declareTime;//?

      // if (!(queryScreeningConditions.declareTime.length>0)){
      //   message.info('申报截止日期为必填项')
      //   return
      // }


      const params = {
        dealerCode:dealerCode.value,
        current:pagination.currentPage,
        size:pagination.pageSize+"",
        incentiveCategory:'',
        category:'',
        begin:'',
        end:'',
        status:[''],
        programCode:'',
        programName:'',
        submissionBegin:'',
        submissionEnd:'',
        // buIdList:buList.value
      }; 
      if (queryScreeningConditions.incentiveCategory !== null && queryScreeningConditions.incentiveCategory!==''){
        params.incentiveCategory = queryScreeningConditions.incentiveCategory;
      }

      if (queryScreeningConditions.category !== null && queryScreeningConditions.category!==''){
        params.category = queryScreeningConditions.category;
      }

      if (queryScreeningConditions.incentiveTime.length>0){
        params.begin = queryScreeningConditions.incentiveTime[0];
        params.end = queryScreeningConditions.incentiveTime[1];
      }

      if (queryScreeningConditions.status !== null && queryScreeningConditions.status!==''){
        params.status.splice(0,params.status.length);  
        params.status = [queryScreeningConditions.status];
      }

      if (queryScreeningConditions.programCode !== null && queryScreeningConditions.programCode!==''){
        params.programCode = queryScreeningConditions.programCode;
      }

      if (queryScreeningConditions.programName !== null && queryScreeningConditions.programName!==''){
        params.programName = queryScreeningConditions.programName;
      }

      if (queryScreeningConditions.declareTime.length>0){
        params.submissionBegin = queryScreeningConditions.declareTime[0];
        params.submissionEnd = queryScreeningConditions.declareTime[1];
      }
      
      console.log(params)
      overViewOption.value = JSON.parse(JSON.stringify(params))
      avaliablePrograms(params).then((res: any): void => {
        // console.log(res)
        dataSource.value = res.program
        isOverView.value = false;

        pagination.total = parseInt(res.totalElements)
        exportParams = Object.assign(params,{current:"",size:""})
        if (dataSource.value === null || dataSource.value === undefined || dataSource.value.length === 0){
          isExport.value = false;
        } else {
          isExport.value = true;
        }

      })
 
  };

      //更改分页组件，包括：直接跳转到指定页，上一页下一页
      const changePagination = (page: number,pagesize: number)=>{
        // pagination.pageSize = pagesize;
        pagination.currentPage = page ;
        if (!isExport.value){
          return
        }
        if (isOverView.value ){

            //aaa
            const params = Object.assign(overViewOption.value ,{
              current:pagination.currentPage,
              size:pagination.pageSize
            })
            // exportParams = Object.assign(overViewOption.value);
            console.log(params)
            avaliablePrograms(params).then((res: any): void => {
              console.log(res)
              dataSource.value = res.program
    
              // pagination.total = res.size;
              pagination.total = parseInt(res.totalElements)
            })
        } else {
          getTableDataByScreeningConditions();
        }

      }
      //更改每页size
      const changePageSzie = (current: number,pagesize: number)=>{
        pagination.pageSize = pagesize;
        pagination.currentPage = 1 ;
        if (!isExport.value){
          return
        }
        if (isOverView.value){

          //aaa
          const params = {
            current:pagination.currentPage,
            size:pagination.pageSize
          }
          // exportParams = Object.assign(overViewOption.value,params);
          console.log(exportParams)
          avaliablePrograms(Object.assign(overViewOption.value,params)).then((res: any): void => {
            console.log(res)
            dataSource.value = res.program
  
            // pagination.total = res.size;
            pagination.total = parseInt(res.totalElements)
          })
        } else {
          getTableDataByScreeningConditions();
        }
      }



    //根据概览数据，查询表格
    const getTableDataByOverView = (option: any) => {
      pagination.currentPage = 1;
      const item = Object.assign(option,{dealerCode:dealerCode.value})
        const params = {
          current:pagination.currentPage,
          size:pagination.pageSize,
          // buIdList:buList.value
          // status:queryStatusArray.value
        }

        overViewOption.value = item;
        exportParams = Object.assign(item);
        if (dataSource.value === null || dataSource.value === undefined || dataSource.value.length === 0){
          isExport.value = false;
        } else {
          isExport.value = true;
        }
        isOverView.value = true;
        console.log(Object.assign(item,params))
         avaliablePrograms(Object.assign(item,params)).then((res: any): void => {
          console.log(res)
          dataSource.value = res.program
          if (dataSource.value === null || dataSource.value === undefined || dataSource.value.length === 0){
            isExport.value = false;
          } else {
            isExport.value = true;
          }

          // pagination.total = res.size;
          pagination.total = parseInt(res.totalElements)
        })
    };

    //查询概览总数
    const getTableDataByOverViewAll = ()=>{
      pagination.currentPage = 1;
      exportParams = Object.assign({dealerCode:dealerCode.value,status:queryStatusArray.value,submissionEnd: moment().format('YYYY-MM-DD')});

      isOverView.value = true;
      // overViewOption.value = exportParams;
      const parma = {
        submissionEnd: moment().format('YYYY-MM-DD'),
        claimMethod:'dealerClaim',
        dealerCode:dealerCode.value,
        current:pagination.currentPage,
        size:pagination.pageSize,
        status:queryStatusArray.value,
        // buIdList:buList.value
      }
      console.log(parma)
      overViewOption.value = parma
      avaliablePrograms(parma).then((res: any): void => {
        console.log(res)
        dataSource.value = res.program
        if (dataSource.value === null || dataSource.value === undefined || dataSource.value.length === 0){
          isExport.value = false;
        } else {
          isExport.value = true;
        }

        // pagination.total = res.size;
        pagination.total = parseInt(res.totalElements)
      })
    }

    interface OfferType {
      id: string;
      pathKey: string;
      nameEn: string;
      nameCn:  string;
    }

    interface IncentiveCategoryType {
      id: string;
      nameEn: string;
      nameCn: string;
      offerType: Array<OfferType>;
    }
    const incentiveCategoryArray = ref<IncentiveCategoryType[]>([]);
    const statusArray = ref<any[]>([]);


    getOfferType().then((res: any): void => {
      incentiveCategoryArray.value = res.incentiveCategory;
    })
    //获取状态
    getStatus().then((res: any): void => {
      statusArray.value = res;
    })

    const dealerID = ref<null|string>('fd0f6cf5-36f5-43a5-8ee2-21b471fefbbb');
    const getDealerId =()=>{
      return getUser().then((res) => {

        // dealerID.value = 'fd0f6cf5-36f5-43a5-8ee2-21b471fefbbb'
        dealerID.value = res.organization.id;
        dealerNameCN.value = res.organization.entityName;
        dealerNameEN.value = res.organization.entityNameEn;
        dealerCode.value = res.organization.entityCode;
        
      }
    );
    }
    const getDealerMessage =()=>{
     return getDealerMessageApi(dealerID.value as string).then((res) => {
          dealerCode.value = res.tmDealerInfoDto.dealerCode;
          dealerNameEN.value = res.tmDealerInfoDto.nameEn;
          dealerNameCN.value = res.tmDealerInfoDto.nameCn;
        }
      );
    }

    const preSearch = (param: any)=>{
      exportParams = param
      avaliablePrograms(param).then((res: any): void => {
        console.log(res)
        dataSource.value = res.program
        if (dataSource.value === null || dataSource.value === undefined || dataSource.value.length === 0){
          isExport.value = false;
        } else {
          isExport.value = true;
        }

        // pagination.total = res.size;
        pagination.pageSize = param.size;
        pagination.currentPage = param.current;
        pagination.total = parseInt(res.totalElements)
      })
    }


    return {
      preSearch,
      overViewOption,
      getDealerId,getDealerMessage,
      queryStatusArray,
      incentiveCategoryArray,statusArray,
      isExport,
      getTableDataByOverViewAll,
        screeningConditions,
        clearScreeningConditions,
        getTableDataByScreeningConditions,
        // getTableData,
        columns,
        dataSource,
        tableHeight,
        getTableDataByOverView,
        pagination,
        changePagination,
        changePageSzie,
        exportTable,
        disableCategory,
        dealerCode,
        dealerNameEN,dealerNameCN,
        buList,
        avaliablePrograms,
        // handleInput
    };
};

export default useDealerList;
