
import { defineComponent,ref } from "vue";
// import { SmileOutlined } from '@ant-design/icons-vue';
import moment from 'moment'
export default defineComponent({
  emits: ["select-table-data"],
  props: {
    overViewValue: {
      type: Object,
      required: true,
    },
    // dealerCode: {
    //   type: String,
    //   required: true,
    // },
  },
  setup(props, context) {
    // console.log('a')
    const showOverView = (type: string) => {
      if (type.length>0){
        const parma = {
          // submissionEnd: moment(props.overViewValue.endtime).format("YYYY-MM-DD"),
          submissionDeadline: moment(props.overViewValue.endtime).format("YYYY-MM-DD"),
          incentiveCategory:type
        }
        context.emit("select-table-data",parma);
        } else {
          const parma = {
          // submissionEnd: moment(props.overViewValue.endtime).format("YYYY-MM-DD"),
          submissionDeadline: moment(props.overViewValue.endtime).format("YYYY-MM-DD"),
        }
        context.emit("select-table-data",parma);
      }

    };

    return {
      showOverView,
    };
  },
});
