
import OverViewBlock from './OverViewBlock/index.vue';
import { defineComponent, ref, onMounted, watch, toRefs } from 'vue';
import { useStore } from 'vuex';
import useDealerList from '@/hooks/claim/dealer/useDealerList';
import {
    getSubmissionOverview,
    matchingCustomerTypeApi,
} from '@/API/claim/delaerClaim/delaer';

import { useRouter, useRoute } from 'vue-router';
export default defineComponent({
    components: {
        OverViewBlock,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        const jumpCode = route.params.programCode;
        const isPre = route.params.isPre;

        const {
            incentiveCategoryArray,
            statusArray,
            disableCategory,
            isExport,
            screeningConditions,
            clearScreeningConditions,
            getTableDataByScreeningConditions,
            columns,
            dataSource,
            tableHeight,
            getTableDataByOverView,
            pagination,
            changePagination,
            changePageSzie,
            getTableDataByOverViewAll,
            exportTable,
            dealerCode,
            queryStatusArray,
            dealerNameEN,
            dealerNameCN,
            buList,
            overViewOption,
            preSearch,
        } = useDealerList();

        //角色的name和code
        // const dealerCode = ref("193120320");
        // const dealerName = ref("dealerName");

        //当前区间可申报激励项目
        const allSum = ref(0);

        //用于判断查询按钮是否可点
        const isDisabled = ref(false);

        watch(screeningConditions, () => {
            const values = Object.values(screeningConditions);
            let sign = true;
            for (let i = 0; i < values.length; i++) {
                if (i === 2 || i === 6) {
                    if ((values[i] as []).length > 0) {
                        sign = false;
                    }
                } else {
                    if (
                        values[i] !== null &&
                        values[i] !== undefined &&
                        (values[i] as string).length > 0
                    ) {
                        sign = false;
                    }
                }
            }
            isDisabled.value = sign;
        });

        //概览

        interface OverViewValueType {
            endtime: any;
            dealer: any;
            customer: any;
            all: any;
        }
        const overViewValueArray = ref<OverViewValueType[]>([]);

        //筛选
        const isOpen = ref(false);
        //关闭筛选按钮
        const colseisOpen = () => {
            isOpen.value = false;
        };

        //分页
        const total = ref<number>(210);
        const size = ref<number>(20);
        const current = ref<number>(0);

        // const exportTable = () => {
        //   console.log('导出表格');
        // };

        //表格
        const operationHandle = (item: any) => {
            sessionStorage.setItem(
                'dealerClaimPre',
                JSON.stringify(overViewOption.value)
            );
            console.log('overViewOption.value', overViewOption.value);
            const param = {
                customerType: item.customerType,
                progDesignId: item.programId,
                programBuId: item.programBu,
                programBuName: item.programBuName,
                programBuSecondId: item.programBuSecond,
                programBuSecondName: item.programBuSecondName,
                programCode: item.programCode,
                programNameCn: item.programNameCn,
                programNameEn: item.programNameEn,
            };
            //异步任务，调用后端接口，不需要返回值，直接进行路由跳转
            matchingCustomerTypeApi(param).then((res: any): void => {
                // console.log('匹配文档')
            });

            //为了2797能返回，需要对显示的信息进行保存
            router.push({
                name: 'DealerProgramCategory',
                params: {
                    activeTab: '0',
                    groupId: item.programId,
                    programCode: item.programCode,
                    // programName:item.programName,
                    programNameCn: item.programNameCn,
                    programNameEn: item.programNameEn,
                    declaredVin: item.declaredVin,
                    programOwner: item.programCreator,
                    releaseDate: item.programReleaseDate,
                    // programBu:item.programBu,

                    programBu: item.programBu,
                    programBuName: item.programBuName,
                    programBuSecond: item.programBuSecond,
                    programBuSecondName: item.programBuSecondName,

                    dealerNameEN: dealerNameEN.value,
                    dealerNameCN: dealerNameCN.value,
                    dealerCode: dealerCode.value,
                },
            });
            //  router.push({ path: '/claim/DealerVINList', query: { endTime:endTime.value, groupId:groupId.value,selectTabs:selectTabs.value,sumNum: sumNum.value,programeCode:programeCode.value,programeName:programeName.value,category:category.value } })
        };

        const initOverView = () => {
            console.log(dealerCode.value);
            getSubmissionOverview({ dealerCode: dealerCode.value }).then(
                (res: any): void => {
                    allSum.value = res.available;
                    queryStatusArray.value = res.avaliableStatus;
                    for (let i = 0; i < res.submission.length; i++) {
                        const item = res.submission[i];
                        const one = {
                            endtime: item.deadline,
                            dealer: item.dealerIncentive,
                            customer: item.customerIncentive,
                            all: item.totalIncentive,
                        };
                        overViewValueArray.value.push(one);
                    }
                }
            );
        };

        const init = async () => {
            // await getDealerId(); //获取ID,code,name相关信息
            // await getDealerMessage();//用获取的ID,获取Code
            await initOverView(); //用获取的Code,进行获取数据
            if (jumpCode !== undefined) {
                screeningConditions.programCode = jumpCode as string;
                getTableDataByScreeningConditions();
            }
            //从2797上一页返回时，根据上次的查询条件再次显示数据
            if (isPre !== undefined) {
                const param = JSON.parse(
                    sessionStorage.getItem('dealerClaimPre') as string
                );
                // overViewOption.value = param;
                Object.assign(overViewOption.value, param);
                console.log('11231', overViewOption.value);
                preSearch(param);
            }
        };

        onMounted(() => {
            const store = useStore();
            console.log(store.state.user);
            dealerCode.value = (store.state
                .user as any).organization.entityCode;
            // dealerCode.value = "193120340"
            dealerNameEN.value = (store.state
                .user as any).organization.entityNameEn;
            dealerNameCN.value = (store.state
                .user as any).organization.entityName;

            for (let i = 0; i < store.state.user.buList.length; i++) {
                buList.value.push(store.state.user.buList[i].id);
            }
            console.log(buList.value);
            init();
        });

        interface OfferType {
            id: string;
            pathKey: string;
            nameEn: string;
            nameCn: string;
        }

        const offtypeArray = ref<OfferType[]>([]);

        //项目类别决定项目类型
        const handleChange = (value: string) => {
            if (value === '' || value === undefined || value === null) {
                screeningConditions.category = null;
                return;
            }
            screeningConditions.category = null;
            for (let i = 0; i < incentiveCategoryArray.value.length; i++) {
                if (incentiveCategoryArray.value[i].nameEn === value) {
                    offtypeArray.value =
                        incentiveCategoryArray.value[i].offerType;
                    disableCategory.value = false;
                }
            }
        };

        const queryTable = () => {
            pagination.currentPage = 1;
            getTableDataByScreeningConditions();
            colseisOpen();
        };

        return {
            incentiveCategoryArray,
            statusArray,
            disableCategory,
            queryTable,
            colseisOpen,
            offtypeArray,
            handleChange,
            isExport,
            getTableDataByOverViewAll,
            overViewValueArray,
            allSum,
            isOpen,
            total,
            size,
            current,
            columns,
            dataSource,
            tableHeight,
            ...toRefs(screeningConditions),
            exportTable,
            // selectTableData,
            clearScreeningConditions,
            getTableDataByScreeningConditions,
            getTableDataByOverView,
            pagination,
            changePagination,
            changePageSzie,
            operationHandle,
            isDisabled,
            dealerCode,
        };
    },
});
